import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setMessage } from "../elements/alert/Action";
import { requestData } from "../helpers/requestData";
import { updateParticipantFromClientAction } from "../../actions/participant";
const Home = (props) => {

    let [updateParticipantFromClient, setUpdateParticipantFromClient] = useState();
    let [content, setContent] = useState();

    // Get Data Query from URL Here
    // Check the vid is Present
    // call the create Participant API

    let allRequestData = requestData(window);

    useEffect(() => {
        if (props.alertMessage == "" && allRequestData.badUrlHitting) {
            props.setMessage({ success: false, message: "You are hitting a bad url." })
        };


    }, [props.alertMessage]);

    // Call the Create Participant API

    useEffect(()=>{
        if(!updateParticipantFromClient && !allRequestData.badUrlHitting){
            // call action of API
            let allQueryParams = allRequestData.urlQueryString;
            let landingURL = allRequestData.landingURL;
            setUpdateParticipantFromClient(true);
            props.updateParticipantFromClientAction(allQueryParams, landingURL);

        }
    })

    return (
        <>
            <div id="terminateDiv" style={{ position: "relative", width: "100%", height: "100vh" }}>
                <div style={{ position: "absolute", top: "52%", left: "47%", transform: "translate(-50%, -50%)", zIndex: "10" }}>
                    <img src={process.env.PUBLIC_URL + '/spinner.png'} alt="loader" style={{ width: "150%", animation: "spin 2s linear infinite" }} />
                </div>
            </div>

            <div id="rejectDiv" style={{ position: "relative", width: "100%", height: "100vh" }}>
                <div style={{ position: "absolute", top: "43%", left: "47%", transform: "translate(-50%, -50%)", zIndex: "10" }}>
                    <img src={process.env.PUBLIC_URL + '/spinner.png'} alt="loader" style={{ width: "150%", animation: "spin 2s linear infinite" }} />
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        alertMessage: state.alert.alertMessage
    }
}
export default connect(mapStateToProps, {
    setMessage, updateParticipantFromClientAction
})(Home);